import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const MealPlans = () => {
  return (
    <Layout title={"SeaWorld Meal Plans"}>
      <div className="bg-gradient-to-b from-[#03264f] to-[#150e11] py-20"></div>
      <div className="relative text-yellow-200 font-bold bg-gradient-to-b from-[#150e11] via-[#1f1415_40%] to-[#4d4142_92%] aspect-[21/7]">
        <h1 className="absolute w-full text-center text-[12vw] top-[20%] left-[50%] -translate-x-[50%] -translate-y-[calc(150%)] lg:-translate-y-[calc(220%-25vw)]">
          MEAL PLANS
        </h1>
        <div className=" flex justify-end px-20">
          <StaticImage
            src="../images/food.png"
            alt="meal plans"
            class="object-contain"
          />
        </div>
      </div>
      <div className="bg-[#4d4142] flex items-center flex-col ">
        <div className="w-10/12 pb-20 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 2xl:gap-10">
          <h2 className="text-white font-bold text-4xl col-span-1 md:col-span-2 xl:col-span-4">
            SeaWorld Meal Voucher Details
          </h2>
          <p className="col-span-1 md:col-span-2 xl:col-span-4 text-white text-xl max-w-[1000px]">
            Food and metal containers are not allowed in the park. Very limited
            food items are OK for small children (under 2 years of age). The
            food in the park may be overpriced which is why you may want to
            consider one of the following meals plans at our Discounted Price.{" "}
            <a
              href="https://seaworld.com/orlando/dining/?tags=Accepts+All-Day+Dining+Deal"
              target="_blank"
              rel="noreferrer"
              className="text-yellow-400 hover:text-red-500 cursor-pointer underline-offset-4 underline decoration-dotted duration-200"
            >
              Click Here{" "}
            </a>
            to see the list of participating restaurants.{" "}
          </p>

          {/* Meal Cards */}
          <div className=" border-2 border-white bg-gradient-to-t from-gray-300 to-white hover:shadow-xl p-5 bg-white rounded-xl hover:-translate-y-1 duration-200 ">
            <div className="aspect-square relative">
              <StaticImage
                src="../images/kids-single-meal.png"
                className="object-contain"
              />
              <p className="-top-8 -right-8 absolute z-10 bg-red-700 text-white font-bold rounded-full p-3 w-20 h-20 text-[2rem] text-center pt-4 shadow-md">
                $10
              </p>
            </div>
            <div className="flex flex-col  ">
              <h3 className="py-2 uppercase font-bold">Child Single Meal</h3>
              <p className="pb-3">
                Children ages 3-9
                <br /> <b>Package includes:</b>
                <br /> + One child meal
                <br />+ One child beverage
              </p>

              <a
                href="https://buy.stripe.com/14k2bR72kcJbbOE4gw"
                target="_blank"
                rel="noreferrer"
                className="bg-red-700 text-white hover:bg-red-800 px-6 py-2 w-full max-w-max"
              >
                BUY NOW
              </a>
            </div>
          </div>
          <div className=" border-2 border-white bg-gradient-to-t from-gray-300 to-white hover:shadow-xl p-5 bg-white rounded-xl hover:-translate-y-1 duration-200 ">
            <div className="aspect-square relative">
              <StaticImage
                src="../images/single-meal.png"
                className="object-contain"
              />
              <p className="-top-8 -right-8 absolute z-10 bg-red-700 text-white font-bold rounded-full p-3 w-20 h-20 text-[2rem] text-center pt-4 shadow-md">
                $17
              </p>
            </div>
            <div className="flex flex-col  ">
              <h3 className="py-2 uppercase font-bold">Adult Single Meal</h3>
              <p className="pb-3">
                Adult ages 10+
                <br /> <b>Package includes:</b>
                <br /> + One adult meal
                <br />+ One regular drink
              </p>

              <a
                href="https://buy.stripe.com/6oEcQvdqIgZraKA6oF"
                target="_blank"
                rel="noreferrer"
                className="bg-red-700 text-white hover:bg-red-800 px-6 py-2 w-full max-w-max"
              >
                BUY NOW
              </a>
            </div>
          </div>
          <div className=" border-2 border-white bg-gradient-to-t from-gray-300 to-white hover:shadow-xl p-5 bg-white rounded-xl hover:-translate-y-1 duration-200 ">
            <div className="aspect-square relative">
              <StaticImage
                src="../images/kids-single-meal.png"
                className="object-contain"
              />
              <p className="-top-8 -right-8 absolute z-10 bg-red-700 text-white font-bold rounded-full p-3 w-20 h-20 text-[2rem] text-center pt-4 shadow-md">
                $20
              </p>
            </div>
            <div className="flex flex-col  ">
              <h3 className="py-2 uppercase font-bold">Child All Day Meals</h3>
              <p className="pb-3">
                Children ages 3-9
                <br /> <b>Package includes:</b>
                <br /> + 1 child meal per hour
                <br />+ 1 child beverage per hour
              </p>

              <a
                href="https://buy.stripe.com/eVabMr1I038BbOE00i"
                target="_blank"
                rel="noreferrer"
                className="bg-red-700 text-white hover:bg-red-800 px-6 py-2 w-full max-w-max"
              >
                BUY NOW
              </a>
            </div>
          </div>
          <div className=" border-2 border-white bg-gradient-to-t from-gray-300 to-white hover:shadow-xl p-5 bg-white rounded-xl hover:-translate-y-1 duration-200 ">
            <div className="aspect-square relative">
              <StaticImage
                src="../images/double-meal.png"
                className="object-contain"
              />
              <p className="-top-8 -right-8 absolute z-10 bg-red-700 text-white font-bold rounded-full p-3 w-20 h-20 text-[2rem] text-center pt-4 shadow-md">
                $35
              </p>
            </div>
            <div className="flex flex-col  ">
              <h3 className="py-2 uppercase font-bold">Adult All Day Meals</h3>
              <p className="pb-3">
                Adults ages 10+
                <br /> <b>Package includes:</b>
                <br /> + 1 adult meal per hour
                <br />+ 1 regular drink per hour
              </p>

              <a
                href="https://buy.stripe.com/bIYeYDaewaB39GwbJ1"
                target="_blank"
                rel="noreferrer"
                className="bg-red-700 text-white hover:bg-red-800 px-6 py-2 w-full max-w-max"
              >
                BUY NOW
              </a>
            </div>
          </div>
        </div>
        <div className="w-full my-40 flex items-center justify-center flex-col gap-5">
          <p className="max-w-[800px] w-10/12 text-lg text-white text-center">
            Want to help or contribute to the event? You may donate to help us
            alleviate some of the cost of our Homecoming Event for our Florida
            Families. Thank you for your support!
          </p>
          <a
            href="https://donate.stripe.com/eVa3fV3Q8fVn6ukdRa"
            target="_blank"
            rel="noreferrer"
            className="max-w-max text-4xl font-bold text-center border-4 text-white rounded-xl hover:bg-red-800 hover:-translate-y-1 hover:shadow-xl duration-200 ease-in-out px-6 py-2 uppercase"
          >
            Donate
          </a>
        </div>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap">
        <Link to="/events" className="bg-[#03264f] w-full p-10">
          <StaticImage
            src="../images/seaworld-field-trip-header.png"
            alt="Seaworld Field day header"
            className="w-full h-full"
          />
        </Link>
        <Link to="/events/#passion" className="bg-[#b4935f] w-full p-10">
          <StaticImage
            src="../images/Passion-Week-Homecoming-Header.png"
            alt="Homecoming Field Day in Sanford, Florida"
            className="w-full h-full"
          />
        </Link>
      </div>
    </Layout>
  )
}

export default MealPlans
